'use client';
import type { GroupProps as MantineGroupProps } from '@mantine/core';
import { Group as MantineGroup } from '@mantine/core';
import isNil from 'lodash/isNil';
import { forwardRef } from 'react';
/**
 * @see https://mantine.dev/core/group/
 */
export interface GroupProps extends Omit<MantineGroupProps, 'color'> {
  /** Controls `flex-wrap` CSS property, `'nowrap'` by default */
  wrap?: MantineGroupProps['wrap'];
  /**
   * @deprecated use justify instead
   */
  position?: 'right' | 'center' | 'left' | 'apart';

  /**
   * @deprecated use gap instead
   */
  spacing?: MantineGroupProps['gap'];
}

export const Group = forwardRef<HTMLDivElement, GroupProps>(
  ({ position, justify, spacing, ...rest }, ref) => {
    return (
      <MantineGroup
        ref={ref}
        justify={resolveJustify({ justify, position })}
        gap={spacing}
        {...rest}
      />
    );
  }
);

const resolveJustify = ({
  justify,
  position
}: Pick<GroupProps, 'position' | 'justify'>): GroupProps['justify'] => {
  if (!isNil(justify)) {
    return justify;
  }

  if (!isNil(position)) {
    switch (position) {
      case 'right':
        return 'flex-end';
      case 'apart':
        return 'space-between';
      case 'center':
        return 'center';
      case 'left':
        return 'left';

      default:
        return undefined;
    }
  }
};
