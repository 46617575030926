import { mapArray } from '../_internal/mapArray';
import { Guid } from './Guid';

export class ColorPalette {
  id: Guid;
  label?: string;
  canManage?: boolean;
  items: ColorPaletteItem[];

  hasItems() {
    return this.items?.length > 0;
  }

  constructor(props?: Partial<ColorPalette>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.items = mapArray(props.items, (x) => new ColorPaletteItem(x));
  }

  addOption(option: Pick<ColorPaletteItem, 'label' | 'value'>) {
    this.items.push(new ColorPaletteItem(option));
  }
}

export class ColorPaletteItem {
  id: Guid;
  label?: string;
  value: string;
  constructor(props?: Partial<ColorPaletteItem>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
  }
}
