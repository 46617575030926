export class OperationResult<TResult = OperationResultType, TData = any> {
  data?: TData;
  result?: TResult;
  success: boolean;
  errorMessage?: string;
  errorData?: any;

  constructor(props?: Partial<OperationResult<TResult, TData>>) {
    props = props || {};
    Object.assign(this, props);
  }

  static success<TResult = OperationResultType, TData = any>(options?: {
    result?: TResult;
    data?: TData;
  }) {
    return new OperationResult<TResult, TData>({
      result: options?.result || (OperationResultType.Success as any),
      success: true,
      data: options?.data
    });
  }

  static error<TResult = OperationResultType, TData = any>(
    error: string,
    options?: { result?: TResult; errorData?: any; data?: TData }
  ) {
    return new OperationResult<TResult, TData>({
      result: options?.result || (OperationResultType.Failed as any),
      success: false,
      errorMessage: error,
      errorData: options?.errorData,
      data: options?.data
    });
  }
}

export enum OperationResultType {
  Success = 'Success',
  Failed = 'Failed'
}
