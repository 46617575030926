import { Guid } from '@komo-tech/core/models/Guid';
import { ScheduleTime } from '@komo-tech/core/models/ScheduleTime';
import { mapArray } from '@komo-tech/core/utils/array';
import { utcDateOrUndefined } from '@komo-tech/core/utils/date';
import { immerable } from 'immer';

import { ButtonDataModel } from '@/common/models/ButtonDataModel';
import { CompetitionFormVariants } from '@/common/models/competitions/CompetitionFormVariants';
import { CookieConsentConfiguration } from '@/common/models/CookieConsentConfiguration';
import { Form } from '@/common/models/form/Form';
import { MetaValues } from '@/common/models/MetaValues';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { PreloadFontInfo } from '@/common/models/PreloadFontInfo';
import { PublishingSchedule } from '@/common/models/PublishingSchedule';
import { FrontSiteCaptcha } from '@/common/models/site/FrontSiteCaptcha';
import { SiteIntegrationInstance } from '@/common/models/site/SiteIntegrationInstance';
import {
  getPrimaryBackgroundColor,
  getPrimaryTextColor
} from '@/common/utils/ButtonFunctions';
import { getSiteUrl } from '@/common/utils/SiteFunctions';

import { Distribution } from '../communications/Distribution';
import { ISiteResolveButton } from './ISiteResolveButton';
import { SiteProperties } from './SiteProperties';
import { SiteStatuses } from './SiteStatuses';

export class Site implements ISiteResolveButton {
  [immerable] = true;
  id: Guid;
  companyId: Guid;
  status: SiteStatuses;
  domain: string;
  domainAliases: string[] = [];
  name: string = '';
  properties: SiteProperties;
  defaultFormId?: Guid;
  defaultPageId?: Guid;
  signUpForm?: Form;
  resetPasswordDistribution?: Distribution;
  confirmEmailDistribution?: Distribution;
  confirmSignInEmailDistribution?: Distribution;
  contactVerifyEmailDistribution?: Distribution;
  createdAt?: Date;
  frontIntegrations?: SiteIntegrationInstance[];
  customFontCss: string;
  preloadFontInfo: PreloadFontInfo[];
  workspaceId: Guid;
  frontCaptcha?: FrontSiteCaptcha;
  publishAt: ScheduleTime;
  unPublishAt: ScheduleTime;

  get isArchived() {
    return this.status === SiteStatuses.Archived;
  }

  get isPublished() {
    return this.status === SiteStatuses.Published;
  }

  get isDraft() {
    return this.status === SiteStatuses.Draft;
  }

  get frontFormVariant(): CompetitionFormVariants {
    if (this.properties.AuthSsoOAuthEnabled) {
      return 'sso';
    }

    if (this.properties.AuthEnabled) {
      return 'user-accounts';
    }

    return 'default';
  }

  get emailInFormsLockedAtTop() {
    switch (this.frontFormVariant) {
      case 'default':
        return !this.properties.ContactVerifyAlwaysShowAllFields;
      case 'user-accounts':
      case 'sso':
      default:
        return true;
    }
  }

  constructor(props?: Partial<Site>) {
    props = props || {};
    Object.assign(this, props);
    this.defaultFormId = Guid.valueOrUndefined(props.defaultFormId);
    this.defaultPageId = Guid.valueOrUndefined(props.defaultPageId);
    this.companyId = Guid.valueOrEmpty(props.companyId);
    this.id = Guid.valueOrEmpty(props.id);
    this.properties = new SiteProperties({
      HeaderEnabled: true,
      BillboardEnabled: true,
      FooterEnabled: true,
      HeaderLogoEnabled: true,
      FooterLogoEnabled: true,
      IsGoogleIndexed: true,
      SocialNetworkEnabled: true,
      ...props.properties
    });
    if (props.signUpForm) this.signUpForm = new Form(props.signUpForm);
    if (props.resetPasswordDistribution) {
      this.resetPasswordDistribution = new Distribution(
        props.resetPasswordDistribution
      );
    }
    if (props.confirmEmailDistribution) {
      this.confirmEmailDistribution = new Distribution(
        props.confirmEmailDistribution
      );
    }
    if (props.confirmSignInEmailDistribution) {
      this.confirmSignInEmailDistribution = new Distribution(
        props.confirmSignInEmailDistribution
      );
    }
    if (props.contactVerifyEmailDistribution) {
      this.contactVerifyEmailDistribution = new Distribution(
        props.contactVerifyEmailDistribution
      );
    }

    if (props.frontIntegrations) {
      this.frontIntegrations = mapArray(
        props.frontIntegrations,
        (x) => new SiteIntegrationInstance(x)
      );
    }
    this.createdAt = utcDateOrUndefined(props.createdAt);
    this.preloadFontInfo = props.preloadFontInfo || [];
    this.workspaceId = Guid.valueOrEmpty(props.workspaceId);
    this.frontCaptcha = props.frontCaptcha
      ? new FrontSiteCaptcha(props.frontCaptcha)
      : undefined;
    this.publishAt = new ScheduleTime(props.publishAt);
    this.unPublishAt = new ScheduleTime(props.unPublishAt);
  }

  getUrl(noScheme?: boolean): string {
    return getSiteUrl(this.domain, noScheme);
  }

  getScriptHead(): string | undefined {
    return this.properties.ScriptHead;
  }

  getScriptBody(): string | undefined {
    return this.properties.ScriptBody;
  }

  getMetaValues() {
    const meta: MetaValues = {
      metaTitle: this.properties.MetaTitle || this.name || 'Site',
      metaDescription: this.properties.MetaDescription,
      metaImage:
        this.properties.MetaImageModel || this.properties.BillboardImage,
      metaIcon: this.properties.MetaIconImage,
      metaIndex: this.properties.IsGoogleIndexed,
      metaGoogleSiteVerification: this.properties.GoogleSiteVerificationCode
    };
    return meta;
  }

  getCookieConsentConfiguration(): CookieConsentConfiguration {
    return {
      backgroundColor: this.properties.CookieConsentBackgroundColor,
      textColor: this.properties.CookieConsentTextColor,
      buttonBackgroundColor: this.properties.CookieConsentButtonBackgroundColor,
      buttonTextColor: this.properties.CookieConsentButtonTextColor,
      title: this.properties.CookieConsentTitle,
      htmlContent: this.properties.CookieConsentContent,
      htmlEditorStateJson: this.properties.CookieConsentContentEditorStateJson
    };
  }

  draftPasswordMatches(password?: string) {
    if (!password) return false;
    return this.properties.DraftPreviewPassword === password;
  }

  resolveButton(options: {
    properties?: SitePageProperties;
    backgroundColor?: string;
    color?: string;
    text: string;
    borderColor?: string;
    url?: string;
  }) {
    const bgColor = getPrimaryBackgroundColor({
      overrideColor: options.backgroundColor,
      properties: options.properties
    });

    return new ButtonDataModel({
      backgroundColor: bgColor,
      color: getPrimaryTextColor({
        overrideColor: options.color,
        properties: options.properties
      }),
      borderColor: options.borderColor || bgColor,
      text: options.text,
      url: options.url
    });
  }

  getColorPalettes() {
    return this.properties.getColorPalettes();
  }

  resolveBadgeColor(): [string, string] {
    return [
      this.properties.BadgeBorderColor ?? '#000000',
      this.properties.BadgeTextColor ?? '#000000'
    ];
  }
  resolveLockedBadgeColor(): [string, string] {
    return [
      this.properties.LockedBadgeBorderColor || '#ced4da', //gray.4
      this.properties.LockedBadgeTextColor || '	#495057' //gray.7
    ];
  }

  get hasPublishOrUnpublishDate() {
    return !!this.publishAt.utc || !!this.unPublishAt.utc;
  }

  get publishingSchedule(): PublishingSchedule {
    return new PublishingSchedule({
      publishAt: this.publishAt,
      unPublishAt: this.unPublishAt
    });
  }

  get timeZoneId(): string | undefined {
    return this.publishAt.timeZoneId ?? this.unPublishAt.timeZoneId;
  }
}

export interface IHasSite {
  site: Site;
}
