export enum SiteStatuses {
  Draft = 'Draft',
  Published = 'Published',
  Archived = 'Archived',
  Deleted = 'Deleted'
}

export const resolveSiteStatusText = (status: SiteStatuses) => {
  switch (status) {
    case SiteStatuses.Draft:
      return 'Draft';
    case SiteStatuses.Archived:
      return 'Archived';
    case SiteStatuses.Published:
      return 'Published';
    default:
      return status;
  }
};
