export enum DistributionTriggers {
  // The distribution is sent manually.
  Manual = 'Manual',

  // The distribution is sent when a prize is awarded.
  Prize = 'Prize',

  // The distribution is sent when a form registration is complete.
  FormRegister = 'FormRegister',

  // The distribution is sent at a specific time
  Scheduled = 'Scheduled',

  // The distribution is sent at a time relative to a life cycle event
  LifeCycle = 'LifeCycle'
}
