export class FrontSiteCaptcha {
  type: CaptchaTypes;
  siteKey: string;
  constructor(props?: Partial<FrontSiteCaptcha>) {
    props = props || {};
    Object.assign(this, props);
  }
}

export enum CaptchaTypes {
  CloudflareTurnstile = 'CloudflareTurnstile'
}
