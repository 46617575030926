import { Guid } from '@komo-tech/core/models/Guid';
import { asBoolean } from '@komo-tech/core/utils/boolean';

import { IntegrationStatuses } from '@/common/models/site/IntegrationStatuses';
import { IntegrationDefinitionTypes } from '@/common/models/site/IntegrationTypes';

import { IntegrationProperties } from './IntegrationProperties';

export class IntegrationDefinition {
  id: Guid;
  name: string;
  type: IntegrationDefinitionTypes;
  status: IntegrationStatuses;
  allowMany?: boolean;
  isFrontEnd?: boolean;
  properties: IntegrationProperties;

  constructor(props?: Partial<IntegrationDefinition>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrEmpty(props.id);
    this.allowMany = asBoolean(props.allowMany);
    this.isFrontEnd = asBoolean(props.isFrontEnd);
    this.properties = new IntegrationProperties(props.properties);
  }
}
