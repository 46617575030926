'use client';
import type { CenterProps as MantineCenterProps } from '@mantine/core';
import {
  Center as MantineCenter,
  CenterFactory as MantineCenterFactory,
  polymorphicFactory as mantinePolymorphicFactory
} from '@mantine/core';

export type CenterProps = MantineCenterProps;
type CenterReturnType = ReturnType<
  typeof mantinePolymorphicFactory<MantineCenterFactory>
>;
/**
 * @see https://mantine.dev/core/center/
 */
export const Center = MantineCenter as CenterReturnType;
